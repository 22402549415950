(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:Genders
   *
   * @description
   *
   */
  angular
  .module('neo.services')
  .factory('Genders', Genders);

  function Genders($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/public/genders');
  }
}());
